import React, { useEffect, useState } from "react";
import PageCard from "../../../../atoms/PageCard";
import SelectDropdown from "../../../../molecules/Dropdown";
import VisitorChart from "./VisitoeChart";
import DateRangePickerShortCuts from "../../../../organisms/dateRangePicker";
import addMonths from "date-fns/addMonths";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
} from "date-fns";
import dayjs from "dayjs";
import FilterComponent from "../../../../organisms/filters/FilterComponent";

const VisitorChartContainer = ({ venueData }) => {
  const [cookies] = useCookies(["b_t", "bid", "buid"]);
  const [filters, setFilters] = useState({});

  const defaultToday = new Date();
  const screenWidth = useScreenWidth();
  const [storeKey, setStoreKey] = useState();
  const [selectedVenue, setSelectedVenue] = useState("All");

  useEffect(() => {
    if (venueData?.length === 1) {
      setSelectedVenue(venueData?.[0]?.value);
    }
  }, [venueData]);
  const [date, setDate] = useState({
    start_date: addHours(startOfDay(startOfMonth(defaultToday)), 12),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)), 12),
  });
  // console.log('test', addDays(date?.start_date , 1).toISOString())

  const [graphData, setGraphData] = useState();
  const [departmentData, setDepartmentData] = useState();
  const [employeeData, setEmployeeData] = useState();

  const handleGraphUpdation = async () => {
    const res = await getData({
      endpoint: "BusinessDashboard/getVisitorAnalytics",
      params: {
        business_id: cookies.bid,
        employee_id:
          atob(cookies?.mode || "") === "Employee" ? cookies.buid : null,
        venues:
          selectedVenue === "All"
            ? null
            : selectedVenue
            ? JSON.stringify([selectedVenue])
            : null,
        department: filters.Departments
          ? JSON.stringify(filters.Departments)
          : null,
        employee: filters.Employees ? JSON.stringify(filters.Employees) : null,
        start_date: date?.start_date,
        end_date: date?.end_date,
      },
      token: cookies.b_t,
    });

    if (res) {
      setGraphData(res?.counts);
    }
  };

  const getDepartmentData = async () => {
    const res = await getData({
      endpoint: "BusinessConfiguration/getDepartmentList",
      params: {
        business_id: cookies.bid,
      },
      token: cookies.b_t,
    });

    if (res) {
      setDepartmentData(res);
    }
  };
  const getEmployeeData = async (data) => {
    const res = await getData({
      endpoint: "BusinessDashboard/getEmployeesByVenuesAndDepartments",
      params: {
        venues:
          selectedVenue === "All" &&
          (data.Venues && data.Venues.length === 1
            ? data.Venues[0] === "All"
            : false)
            ? null
            : JSON.stringify(data.Venues),
        departments: data.Departments ? JSON.stringify(data.Departments) : null,
      },
      token: cookies.b_t,
    });

    if (res) {
      const formattedData = res.map((item) => ({
        value: item.value,
        label: item.lebel,
      }));
      setEmployeeData(formattedData);
    }
  };

  useEffect(() => {
    getDepartmentData();
    handleGraphUpdation();
  }, []);

  // useEffect(() => {
  //   setFilters((prevFilters) => {
  //     const { Employees, ...rest } = prevFilters; // Remove the Employees array from filters
  //     return { ...rest }; // Return the updated filters without Employees
  //   });
  // }, [employeeData]);

  const filtersData = {
    Venues: venueData,
    Departments: departmentData,
    Employees: employeeData,
  };

  return (
    <>
      <style>
        {`
      @media (min-width: 500px) and (max-width: 1230px) {
            .pagecard-responsive-tab {
              padding: 1rem;
            }
            .rmove-heder-margin{
            width:100%;
            h3{
            margin-bottom:0 !important;
            }
            }

            .drop-items-hone-tab {
              width: 100%;
              justify-content: space-between;
            }
              .rs-picker-daterange-panel{
              }
          }
        `}
      </style>
      <PageCard classes={"pagecard-responsive-tab"}>
        <div>
          <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
            <div className="d-flex justify-content-between align-items-center mb-2 rmove-heder-margin">
              <h3 className="h2-i mb-4">Visitor Analytics</h3>
              <button
                className="btn btn-primary tab-employee-responsive-block"
                style={{ height: "46px" }}
                onClick={() => handleGraphUpdation()}
              >
                Update Graph
              </button>
            </div>
            <div className="d-flex gap-3 drop-items-hone-tab">
              {/* <SelectDropdown
                type="dashboard"
                placeholder="Select Venue"
                data={venueData}
                selectedValue={selectedVenue}
                onChange={(option) => setSelectedVenue(option)}
              /> */}
              <DateRangePickerShortCuts setDate={setDate} />
              <FilterComponent
                filterData={filtersData}
                onFilterChange={async (filters) => {
                  // if (filters.Employees && Array.isArray(filters.Employees)) {
                  //   const filteredEmployees = employeeData.filter(
                  //     (emp) => filters.Employees.includes(emp.value) // Match by 'value'
                  //   );

                  //   const updatedFilters = {
                  //     ...filters,
                  //     Employees: filteredEmployees,
                  //   };

                  //   await setFilters(updatedFilters);
                  // } else {
                  await setFilters(filters);
                  // }
                }}
                immediateChange={true}
                setFilters={setFilters}
                filters={filters}
                activeKey={storeKey}
                onEmitChange={(option, selectedKey) => {
                  if (selectedKey !== "Employees") {
                    getEmployeeData(option);
                  }
                  setStoreKey(selectedKey);
                }}
              />
              <button
                className="btn btn-primary tab-employee-responsive-none"
                style={{ height: "46px" }}
                onClick={() => handleGraphUpdation()}
              >
                Update Graph
              </button>
            </div>
          </div>

          <VisitorChart data={graphData} />
        </div>
      </PageCard>
    </>
  );
};

export default VisitorChartContainer;
